import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faRightToBracket,
  faPersonWalkingArrowRight,
  faBookOpen,
} from "@fortawesome/free-solid-svg-icons";
import Dropdown from "react-bootstrap/Dropdown";

const Navbar = ({ currentUser, logOut, register, login }) => {
  return (
    <nav
      className="navbar"
      style={{
        backgroundImage: "linear-gradient(15deg, #4206b8 0%, #864df7 100%)",
        position: "sticky",
        top: 0,
        zIndex: 100,
      }}
    >
      {currentUser && currentUser.name && (
        <Dropdown style={{ marginLeft: "20px" }}>
          <Dropdown.Toggle
            variant="link"
            id="dropdown-menu"
            className="navbar-brand"
            style={{
              color: "#5469D4",
              fontSize: "18px",
              fontFamily: "Alexandria, sans-serif",
              backgroundColor: "white",
            }}
          >
            {currentUser.name}
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{ backgroundColor: "#ffffff", borderRadius: "5px" }}
          >
            <Dropdown.Item href="/profile" className="dropdown-item ">
              المحاضرات{" "}
              <FontAwesomeIcon
                icon={faBookOpen}
                style={{ marginLeft: "5px" }}
              />
            </Dropdown.Item>
            <hr className="dropdown-divider" />
            <Dropdown.Item onClick={logOut} className="dropdown-item logout">
              تسجيل الخروج{" "}
              <FontAwesomeIcon
                icon={faPersonWalkingArrowRight}
                style={{ marginLeft: "5px" }}
              />
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      )}
      {!currentUser && (
        <div className="navbar-nav ml-auto responsive-nav-items">
          <div
            className="nav-item-container"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "white",
              marginRight: "25px",
              width: "100%",
              transition: "background-color 0.3s ease",
            }}
          >
            <li className="nav-item underline-link">
              <Link
                to={"/payment"}
                className="nav-link"
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "300",
                  fontFamily: "Alexandria, sans-serif",
                  marginLeft: "20px",
                }}
              >
                طرق الدفع
              </Link>
            </li>
            {register == 1 && (<li className="nav-item underline-link">
              <Link
                to={"/register"}
                className="nav-link"
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "300",
                  fontFamily: "Alexandria, sans-serif",
                  marginLeft: "20px",
                }}
              >
                تسجيل جديد
              </Link>
            </li>)}
            {login == 1 && (<li className="nav-item">
              <Link
                to={"/login"}
                className="nav-link underline-link"
                style={{
                  color: "#ffffff",
                  fontSize: "18px",
                  fontWeight: "300",
                  fontFamily: "Alexandria, sans-serif",
                  marginLeft: "20px",
                }}
              >
                تسجيل الدخول
              </Link>
            </li>)}
            <FontAwesomeIcon
              icon={faRightToBracket}
              style={{
                color: "#ffffff",
                fontSize: "18px",
                fontWeight: "300",
                fontFamily: "Alexandria, sans-serif",
                marginLeft: "5px",
              }}
            />
          </div>
        </div>
      )}
      <ul className="nav" style={{ marginRight: "15px" }}>
        <li className="nav-item title">
          <Link
            to={currentUser ? "/profile" : "/"}
            style={{
              marginRight: "15px",
              color: "#ffffff",
              fontSize: "18px",
              fontWeight: "300",
            }}
            className={`nav-link active underline-link title ${!currentUser ? "title-responsive" : ""
              }`}
          >
            دورة المخطوبين
          </Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
