import React, { useState, useEffect } from "react";
import axios from "../api/index";
import UserService from "../services/user.service";
import logo from "../assets/images/VioletCross.png";
import instructionsImage from "../assets/images/instructionsImage.jpeg";
import background from "./52logo.png";
import { Link } from "react-router-dom";

const Home = () => {
  const [content, setContent] = useState("");

  const [register, setRegister] = useState(0);
  const [login, setLogin] = useState(0);

  const loginDetails = async () => {
    return axios({
      url: `/api/get/auth/info`,
      method: "GET",
    })
      .then((res) => {
        // console.log("helloooooooooo");
        let data = res.data.data;
        //  data = data;
        //  let parser = new DOMParser();
        //  let doc = parser.parseFromString(data, 'text/html');
        //  data = doc.body;

        data = JSON.parse(data);

        data.forEach((element) => {
          if (element.name === "register") {
            setRegister(parseInt(element.value));
          }
          if (element.name === "login") {
            setLogin(parseInt(element.value));
          }
        });

        //  console.log('will check', data)
        //  console.log('alertt', alertt)
      })
      .catch((err) => { });
  };

  loginDetails();

  useEffect(() => {
    UserService.getPublicContent().then(
      (response) => {
        setContent(response.data);
      },
      (error) => {
        const _content =
          (error.response && error.response.data) ||
          error.message ||
          error.toString();

        setContent(_content);
      }
    );
  }, []);

  return (
    <div className="container">
      <div className="card text-center glass-card">
        <div className="card-header">
          <img
            src={logo}
            alt="Logo"
            style={{ width: "100%", height: "auto", maxWidth: "261px" }}
          />
        </div>
        <div className="card-body">
          <h3 className="card-title title-main">
            كنيسة الشهيد العظيم مارمرقس الرسول بمصر الجديدة
          </h3>
          <h3 className="card-title title-sub">
            <span className="line-before"></span>
            دورة المخطوبين ال ٥٩
            <span className="line-after"></span>
          </h3>

          <div className="text-right" style={{ marginTop: "20px" }}>
            <div className="text-center">
              {login == 1 && (
                <Link
                  to="/login"
                  style={{ color: "white", textDecoration: "none" }}
                >
                  <button className="btn btn-primary-custom btn-lg">
                    تسجيل الدخول
                  </button>
                </Link>
              )}
              {register == 1 &&
                <Link to="/register" style={{ textDecoration: "none" }}>
                  <button
                    className="btn btn-primary-custom btn-lg"
                    style={{
                      margin: "5px",
                      backgroundColor: "#CBA709",
                    }}
                  >
                    تسجيل جديد
                  </button>
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
      <div className="card text-center glass-card">
        <div className="card-body">
          <img
            src={instructionsImage}
            alt="Logo"
            style={{ width: "100%", height: "auto", maxWidth: "700px" }}
          />
        </div>
      </div>
    </div >
  );
};

export default Home;
