import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import axios from "../api/index";
import { useHistory } from "react-router-dom";
import "react-phone-number-input/style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLeftLong, faRightLong } from "@fortawesome/free-solid-svg-icons";
import PhoneInput, {
  formatPhoneNumber,
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
} from "react-phone-number-input/";

const required = (value) => {
  if (!value) {
    return (
      <div className="alert alert-danger" role="alert">
        !هذا الحقل مطلوب
      </div>
    );
  }
};
const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="alert alert-danger" role="alert">
        هذا البريد الالكتروني غير صحيح!
      </div>
    );
  }
};

const vid = (value) => {
  if (value.length !== 14) {
    return (
      <div className="alert alert-danger" role="alert">
        من فضلك ادخل الرقم القومي بطريقة صحيحة.
      </div>
    );
  } else {
    // axios({
    //   url: '/registration/checkid',
    //   body: {
    //     rakamKawmy: value,
    //   },
    //   headers: {
    //     //'Content-Type': 'multipart/form-data',
    //     //'x-access-token': userToken,
    //   },
    //   method: 'POST',
    // })
    //   .then(res => {
    //     console.log('Already taken, ', res);
    //     return (
    //       <div className="alert alert-danger" role="alert">
    //         Already taken
    //       </div>
    //     );
    //   })
    //   .catch(err => {
    //     console.warn('Failed to load chapter verses', err);
    //     return (
    //       <div className="alert alert-danger" role="alert">
    //       Already taken error
    //       </div>
    //     );
    //   });
  }
};

const vmobile = (value) => {
  let result = value.substring(0, 3);

  if (result === "+20") {
    if (value.length !== 13) {
      return false;
    }
  }
  return isValidPhoneNumber(value);

  // if (true) {

  //   return alert(" من فضلك ادخل رقم المحمول بطريقة صحيحة.");
  //   // return (

  //   //   <div className="alert alert-danger" role="alert">
  //   //     من فضلك ادخل رقم المحمول بطريقة صحيحة.
  //   //   </div>
  //   // );
  // }
};

const vgender = (value) => {
  // console.log("genderrrrrr", value);
  /*if (value.length !== 11) {
    return (
      <div className="alert alert-danger" role="alert">
      من فضلك ادخل رقم المحمول بطريقة صحيحة.
      </div>
    );
  }*/
};

const vname = (value) => {
  if (value.length < 5) {
    return (
      <div className="alert alert-danger" role="alert">
        من فضلك ادخل الاسم بطريقة صحيحة.
      </div>
    );
  }
};

const RegistrationForm = () => {
  //basics
  const form = useRef();
  const checkBtn = useRef();
  const [single, setSingle] = useState("");
  const [error, setMessage] = useState("");
  const [sucess, setSucess] = useState("");
  const [loading, setLoading] = useState(false);

  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const [bdate, setBdate] = useState("");
  const [father, setFather] = useState("");
  const [knowus, setKnowus] = useState("");
  const [fatherchurch, setFatherchurch] = useState("");
  const [gender, setGender] = useState("male");
  const [address, setAddress] = useState("");
  const [addressnow, setAddressnow] = useState("");
  const [church, setChurch] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  // const [accept, setAccept] = useState(false);
  // const [taccept, settAccept] = useState(false);
  //second one
  const [tname, settName] = useState("");
  const [tid, settId] = useState("");
  const [tbdate, settBdate] = useState("");
  const [tfather, settFather] = useState("");
  const [tknowus, settKnowus] = useState("");
  const [tfatherchurch, settFatherchurch] = useState("");
  const [tgender, settGender] = useState("female");
  const [taddress, settAddress] = useState("");
  const [taddressnow, settAddressnow] = useState("");
  const [tchurch, settChurch] = useState("");
  const [tmobile, settMobile] = useState("");
  const [temail, settEmail] = useState("");

  //files upload
  const [fileone, setFileone] = useState("");
  const [filetwo, setFiletwo] = useState("");
  const [filethree, setFilethree] = useState("");
  const [tfileone, settFileone] = useState("");
  const [tfiletwo, settFiletwo] = useState("");
  const [tfilethree, settFilethree] = useState("");

  // const [password, setPassword] = useState("");
  const [successful, setSuccessful] = useState(false);

  const { message } = useSelector((state) => state.message);
  //const dispatch = useDispatch();

  // const onChangeSingle = (e) => {
  //   const single = e.target.value;
  //   setSingle(single);
  // };
  // handle male

  const onChangeId = (e) => {
    const id = e.target.value;
    setId(id);
  };

  const onChangeBdate = (e) => {
    const bdate = e.target.value;
    setBdate(bdate);
  };

  const onChangeMobile = (e) => {
    const mobile = e;
    setMobile(mobile);
  };

  const onChangeGender = (e) => {
    // console.log("hiiii gender");
    // console.log(e.target.value);
    const gender = e.target.value;
    setGender(gender);
  };

  const onChangeName = (e) => {
    const name = e.target.value;
    setName(name);
  };

  const onChangeAddress = (e) => {
    const address = e.target.value;
    setAddress(address);
  };

  const onChangeAddressnow = (e) => {
    const addressnow = e.target.value;
    setAddressnow(addressnow);
  };

  const onChangeChurch = (e) => {
    const church = e.target.value;
    setChurch(church);
  };

  const onChangeFather = (e) => {
    const father = e.target.value;
    setFather(father);
  };

  const onChangeFatherchurch = (e) => {
    const fatherchurch = e.target.value;
    setFatherchurch(fatherchurch);
  };

  const onChangeKnowus = (e) => {
    const knowus = e.target.value;
    setKnowus(knowus);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  //handle 2nd partner
  const onChangetId = (e) => {
    const tid = e.target.value;
    settId(tid);
  };

  const onChangetBdate = (e) => {
    const tbdate = e.target.value;
    settBdate(tbdate);
  };

  const onChangetMobile = (e) => {
    const tmobile = e;
    settMobile(tmobile);
  };

  const onChangetGender = (e) => {
    const selectedOption = e.target.value;
    setSelectedGender(selectedOption);
  };

  const onChangetName = (e) => {
    const tname = e.target.value;
    settName(tname);
  };

  const onChangetAddress = (e) => {
    const taddress = e.target.value;
    settAddress(taddress);
  };

  const onChangetAddressnow = (e) => {
    const taddressnow = e.target.value;
    settAddressnow(taddressnow);
  };

  const onChangetChurch = (e) => {
    const tchurch = e.target.value;
    settChurch(tchurch);
  };

  const onChangetFather = (e) => {
    const tfather = e.target.value;
    settFather(tfather);
  };

  const onChangetFatherchurch = (e) => {
    const tfatherchurch = e.target.value;
    settFatherchurch(tfatherchurch);
  };

  const onChangetKnowus = (e) => {
    const tknowus = e.target.value;
    settKnowus(tknowus);
  };

  const onChangetEmail = (e) => {
    const temail = e.target.value;
    settEmail(temail);
  };

  const [accept, setAccept] = useState(false);

  const handleCheckboxChange = () => {
    setAccept(!accept);
  };

  const onFileChangeone = (event) => {
    const selectedFile = event.target.files[0];
    setFileone(selectedFile);
  };
  const onFileChangetwo = (event) => {
    const selectedFile = event.target.files[0];
    setFiletwo(selectedFile);
  };
  const onFileChangethree = (event) => {
    const selectedFile = event.target.files[0];
    setFilethree(selectedFile);
  };

  const ontFileChangeone = (event) => {
    const selectedFile = event.target.files[0];
    settFileone(selectedFile);
  };
  const ontFileChangetwo = (event) => {
    const selectedFile = event.target.files[0];
    settFiletwo(selectedFile);
  };
  const ontFileChangethree = (event) => {
    const selectedFile = event.target.files[0];
    settFilethree(selectedFile);
  };
  const history = useHistory();

  const handleRegister = (props) => {
    setLoading(true);
    props.preventDefault();
    form.current.validateAll();
    if (!vmobile(mobile)) {
      setMessage("من فضلك اكتب رقم الهاتف بشكل صحيح");
      setLoading(false);
      return;
    }
    if (single === "couple") {
      if (!vmobile(tmobile)) {
        setMessage("!من فضلك ادخل كل البيانات بشكل صحيح");
        setLoading(false);
        return;
      }
    }
    // if (currentPage === 4 && !accept) {
    //   setMessage("!من فضلك وافق علي الشروط والاحكام");
    //   setLoading(false);
    //   return;
    // }
    if (currentPage === 4 || currentPage === 3) {
      if (!accept) {
        setMessage("!من فضلك وافق علي الشروط والاحكام");
        setLoading(false);
        return;
      }
    }

    // console.log("here ana 3adet el 1st accept");

    const formData = new FormData();

    if (fileone !== "") {
      formData.append("file1", fileone, "fileone");
    } else {
      setMessage("!من فضلك اختر صورة البطاقة من الأمام");
      setLoading(false);
      return;
    }

    if (filetwo !== "") {
      formData.append("file2", filetwo, "filetwo");
    } else {
      setMessage("!من فضلك اختر صورة البطاقة من الخلف");
      setLoading(false);
      return;
    }

    if (filethree !== "") {
      formData.append("file3", filethree, "filethree");
    } else {
      setMessage("!من فضلك اختر صورة شخصية ");
      setLoading(false);
      return;
    }
    // console.log("done el 1st files");
    var couple = [];

    if (single === "couple") {
      // if (!taccept) {
      //   setMessage("!من فضلك وافق علي الشروط والأحكام");
      //   setLoading(false);
      //   return;
      // }

      if (tfileone !== "") {
        formData.append("file4", tfileone, "file4");
      } else {
        setMessage("!من فضلك اختر صورة البطاقة من الأمام");
        setLoading(false);
        return;
      }

      if (tfiletwo !== "") {
        formData.append("file5", tfiletwo, "file5");
      } else {
        setMessage("!من فضلك اختر صورة البطاقة من الخلف");
        setLoading(false);
        return;
      }

      if (tfilethree !== "") {
        formData.append("file6", tfilethree, "file6");
      } else {
        setMessage("!من فضلك اختر صورة شخصية ");
        setLoading(false);
        return;
      }
      // console.log("done el 2nd files");

      if (
        id === "" ||
        name === "" ||
        mobile === "" ||
        mobile === "" ||
        email === "" ||
        bdate === "" ||
        address === "" ||
        addressnow === "" ||
        church === "" ||
        father === "" ||
        fatherchurch === "" ||
        knowus === "" ||
        tid === "" ||
        tname === "" ||
        tmobile === "" ||
        tmobile === "" ||
        temail === "" ||
        tbdate === "" ||
        taddress === "" ||
        taddressnow === "" ||
        tchurch === "" ||
        tfather === "" ||
        tfatherchurch === "" ||
        tknowus === ""
      ) {
        setMessage("!من فضلك ادخل كل البيانات بشكل صحيح");
        setLoading(false);
        return;
      }

      // console.log("done el 2st data");
      // console.log(sgender);
      // console.log(tsgender);
      couple = [
        {
          rakamKawmy: id,
          fullName: name,
          mobile: mobile,
          email: email,
          bdate: bdate,
          gender: gender,
          address: address,
          addressnow: addressnow,
          church: church,
          father: father,
          fatherchurch: fatherchurch,
          knowus: knowus,
        },
        {
          rakamKawmy: tid,
          fullName: tname,
          mobile: tmobile,
          email: temail,
          bdate: tbdate,
          gender: tgender,
          address: taddress,
          addressnow: taddressnow,
          church: tchurch,
          father: tfather,
          fatherchurch: tfatherchurch,
          knowus: tknowus,
        },
      ];
      formData.append("persons", JSON.stringify(couple));
      // console.log("done kolo w da5el 3al axios", couple);
    } else {
      if (
        id === "" ||
        name === "" ||
        mobile === "" ||
        mobile === "" ||
        email === "" ||
        bdate === "" ||
        address === "" ||
        addressnow === "" ||
        church === "" ||
        father === "" ||
        fatherchurch === "" ||
        knowus === ""
      ) {
        setMessage("!من فضلك ادخل كل البيانات بشكل صحيح");
        setLoading(false);
        return;
      }
      // console.log("done el 1st data");
      couple = [
        {
          rakamKawmy: id,
          fullName: name,
          mobile: mobile,
          email: email,
          bdate: bdate,
          gender: gender,
          address: address,
          addressnow: addressnow,
          church: church,
          father: father,
          fatherchurch: fatherchurch,
          knowus: knowus,
        },
      ];
      formData.append("persons", JSON.stringify(couple));
      // console.log("done kolo w da5el 3al axios", couple);
    }
    setSucess("...جاري تحميل الصور");
    var confirmMessage = "برجاء التاكد ان الأسماء باللغة العربية مطابقة للرقم القومي و كذلك النوع" + "\n";
    couple.forEach(element => {
      // console.log(element.gender);
      if (element.gender == "male") {
        confirmMessage += "الابن المبارك: " + element.fullName + "\n";
      }
      if (element.gender == "female") {
        confirmMessage += "الابنة المباركة: " + element.fullName + "\n";
      }
    });
    confirmMessage += "هذه الأسماء هي التي ستُكتب في الشهادة ولا يمكن تعديلها."
    if (window.confirm(confirmMessage)) {
      return axios({
        url: "/api/registration",
        body: formData,
        headers: {},
        method: "POST",
      })
        .then((res) => {
          // console.log("here ana gebt el results, ", res);
          // console.log("status, ", res.status);
          if (res.status === 200) {
            setLoading(false);
            history.push("/payment");
            // window.location.reload();
            setCurrentPage(3);
          } else if (res.status === 403) {
            setLoading(false);
            setMessage("!لقد تم اضافة هذا الرقم القومي من قبل");
          } else {
            setMessage("!من فضلك ادخل البيانات بشكل صحيح");
            setLoading(false);
          }
        })
        .catch((err) => {
          // console.log("here error", err);
          if (err.response.status === 403) {
            setLoading(false);
            setMessage(err.response.data.errors.toString());
          } else {
            setLoading(false);
            setMessage("حدث خطأ,برجاء المحاولة مرة اخري");
          }
          // console.log("here error", err.response.status);
          return;
        });
    }
    else {
      setLoading(false);
      setMessage("");
      setSucess("");
    }

  };

  const onChangeSingle = (event) => {
    setSingle(event.target.value);
    setGender("male");
    settGender("female");
    // setSelectedGender(""); // Reset the selected gender when changing the 'أنا وخطيبي/خطيبتي' option
    setCurrentPage(1); // Reset the page to the first page when changing the option
  };

  // const onChangeSecondDropdown = (event) => {
  //   const selectedOption = event.target.value;
  //   setFirstFormType(selectedOption === "الخطيب" ? "groom" : "bride");
  // };

  // const handleNext = () => {
  //   setFirstFormType(firstFormType === "groom" ? "bride" : "groom");
  // };

  // const handlePrevious = () => {
  //   setFirstFormType(firstFormType === "groom" ? "bride" : "groom");
  // };
  const [selectedGender, setSelectedGender] = useState("");
  const [genderSelected, setGenderSelected] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const genders = ["الخطيب", "الخطيبة"];
  const onChangeDropdown = (event) => {
    // console.log(event.target.value);
    // if(currentPage == 1)
    // setGender(event.target.value);

    setSelectedGender(event.target.value);
    // if (currentPage == 1) {
    setGender(event.target.value == "الخطيب" ? "male" : "female");
    settGender(event.target.value == "الخطيب" ? "female" : "male");
    // }
    // setGenderSelected(true);
  };

  const onPreviousPage = () => {
    setCurrentPage(1);
    const nextGender = selectedGender === genders[0] ? genders[1] : genders[0];
    setSelectedGender(nextGender);
  };

  const showNextAndPreviousButtons = single === "couple" && currentPage === 1;

  const allRequiredFieldsAreFilled = () => {
    return (
      id !== "" &&
      name !== "" &&
      mobile !== "" &&
      email !== "" &&
      bdate !== "" &&
      address !== "" &&
      addressnow !== "" &&
      church !== "" &&
      father !== "" &&
      fatherchurch !== "" &&
      knowus !== "" &&
      fileone !== "" &&
      filetwo !== "" &&
      filethree !== ""
    );
  };
  const allRequiredFieldsAreFilled2 = () => {
    return (
      tid !== "" &&
      tname !== "" &&
      tmobile !== "" &&
      temail !== "" &&
      tbdate !== "" &&
      taddress !== "" &&
      taddressnow !== "" &&
      tchurch !== "" &&
      tfather !== "" &&
      tfatherchurch !== "" &&
      tknowus !== "" &&
      tfileone !== "" &&
      tfiletwo !== "" &&
      tfilethree !== ""
    );
  };

  const [message1, setMessage1] = useState("");
  const [message2, setMessage2] = useState("");

  const handleNextToThirdPage = () => {
    if (!allRequiredFieldsAreFilled2()) {
      // console.log("Not all second required fields 2 are filled.");
      setMessage2("!من فضلك ادخل كل بيانات الطرف الثاني بشكل صحيح");
      // setMessage2(error);
    } else {
      setMessage2("");
      setCurrentPage(3);
    }
  };

  const handleNextClick = () => {
    if (!allRequiredFieldsAreFilled()) {
      // console.log("Not all required fields are filled.");
      setMessage1("!من فضلك ادخل كل البيانات بشكل صحيح");
      // setMessage1(error);
    } else {
      // console.log("All required fields are filled.");
      setMessage1("");
      setCurrentPage(currentPage + 1);
      const nextGender =
        selectedGender === genders[1] ? genders[0] : genders[1];
      setSelectedGender(nextGender);
    }
  };

  const onNextPage = () => {
    setCurrentPage(2);
    const nextGender = selectedGender === genders[1] ? genders[0] : genders[1];
    setSelectedGender(nextGender);
  };

  const setNextPage = () => {
    if (!allRequiredFieldsAreFilled()) {
      // console.log("Not all required fields are filled.");
      setMessage1("!من فضلك ادخل كل البيانات بشكل صحيح");
      // setMessage1(error);
    } else {
      // console.log("All required fields are filled.");
      setMessage1("");
      setCurrentPage(4);
    }
  };

  return (
    <div className="register-container">
      <div className="register-form">
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div hidden={currentPage === 3 || currentPage === 4}>
                <div>:سأحضر هذة الدورة</div>
                <select
                  onChange={onChangeSingle}
                  validations={[required, vgender]}
                >
                  <option value="single">بمفردي</option>
                  <option value="couple">أنا وخطيبي/خطيبتي</option>
                </select>
              </div>

              {/* Single form */}
              {single !== "couple" && currentPage === 1 && (
                <div>
                  <div className="form-group">
                    <label htmlFor="id">
                      برجاء ادخال الرقم القومي كاملاً (ال ١٤ رقم)
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="id"
                      value={id}
                      onChange={onChangeId}
                      validations={[required, vid]}
                      placeholder="برجاء كتابة الأرقام (فقط) باللغة الانجليزية"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="name">
                      الاسم كاملًا باللغة العربية ( كما هو ببطاقة الرقم القومى)
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="name"
                      value={name}
                      onChange={onChangeName}
                      validations={[required, vname]}
                    />
                  </div>
                  <div>
                    <label style={{ margin: "5px" }}>:النوع</label>
                    <select
                      value={gender}
                      onChange={onChangeGender}
                      validations={[required, vgender]}
                    >
                      <option value="male" disabled={selectedGender === "male"}>
                        ذكر
                      </option>
                      <option
                        value="female"
                        disabled={selectedGender === "female"}
                      >
                        أنثى
                      </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobile">
                      (whatsapp) رقم المحمول به واتساب
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={mobile}
                      name="mobile"
                      defaultCountry="EG"
                      onChange={onChangeMobile}
                      rules={{
                        validate: (value) => vmobile(value),
                      }}
                      error={
                        mobile
                          ? vmobile
                            ? undefined
                            : "Invalid phone number"
                          : "Phone number required"
                      }
                    />
                    {mobile && formatPhoneNumberIntl(mobile)}
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobile">تاريخ الميلاد</label>
                    <Input
                      type="date"
                      className="form-control"
                      name="bdate"
                      value={bdate}
                      onChange={onChangeBdate}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="email">البريد الالكتروني</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                      placeholder="example@gmail.com"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="address">
                      العنوان بالرقم القومي بالتفصيل
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="address"
                      value={address}
                      onChange={onChangeAddress}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="addressnow">العنوان الحالي بالتفصيل</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="addressnow"
                      value={addressnow}
                      onChange={onChangeAddressnow}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="church">
                      (الكنيسة التابع لها (اسم الكنيسة والمنطقة/الحي
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="church"
                      value={church}
                      onChange={onChangeChurch}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="father">أب الإعتراف</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="father"
                      value={father}
                      onChange={onChangeFather}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="fatherchurch">كنيسة أب الإعتراف</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="fatherchurch"
                      value={fatherchurch}
                      onChange={onChangeFatherchurch}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="knowus">
                      كيف عرفت بالدورة ال ٥٩ للمخطوبين؟
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="knowus"
                      value={knowus}
                      onChange={onChangeKnowus}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <div className="form-group">
                      <div className="form-group">
                        <label htmlFor="tfatherchurch" className="file-label">
                          (برجاء تحميل صورة واضحة لبطاقة الرقم القومى (وجه
                        </label>
                        <span
                          className="file-upload"
                          onClick={() =>
                            document.getElementById("fileInput").click()
                          }
                        >
                          {fileone && fileone.name}
                          {!fileone && (
                            <span
                              className="file-placeholder"
                              style={{ margin: "5px 0", textAlign: "center" }}
                            >
                              Choose a file
                            </span>
                          )}
                          <input
                            type="file"
                            onChange={onFileChangeone}
                            className="file-input"
                            id="fileInput"
                            style={{ display: "none" }}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="tfatherchurch">
                      (تحميل صورة واضحة لبطاقة الرقم القومى (ظهر
                    </label>
                    <span
                      className="file-upload"
                      onClick={() =>
                        document.getElementById("fileInput2").click()
                      }
                    >
                      {filetwo && filetwo.name}
                      {!filetwo && (
                        <span
                          className="file-placeholder"
                          style={{ margin: "5px 0", textAlign: "center" }}
                        >
                          Choose a file
                        </span>
                      )}
                      <input
                        type="file"
                        onChange={onFileChangetwo}
                        className="file-input"
                        id="fileInput2"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="tfatherchurch">
                      برجاء تحميل صورة شخصية
                    </label>
                    <span
                      className="file-upload"
                      onClick={() =>
                        document.getElementById("fileInput3").click()
                      }
                    >
                      {filethree && filethree.name}
                      {!filethree && (
                        <span
                          className="file-placeholder"
                          style={{ margin: "5px 0", textAlign: "center" }}
                        >
                          Choose a file
                        </span>
                      )}
                      <input
                        type="file"
                        onChange={onFileChangethree}
                        className="file-input"
                        id="fileInput3"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                  <hr />
                  <div className="form-group">
                    <button
                      className="btn-register"
                      disabled={loading}
                      onClick={setNextPage}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>تسجيل</span>
                    </button>
                    <br />
                    {/* <span style={{ textAlign: "center", color: "red" }}>
                      <li dir="rtl">
                        {" "}
                        اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل. (انظر طرق
                        الدفع)
                      </li>
                    </span> */}
                  </div>
                  {error && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  )}

                  {sucess && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-success"
                        }
                        role="alert"
                      >
                        {sucess}
                      </div>
                    </div>
                  )}
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </div>
              )}
              {currentPage === 4 && (
                <div>
                  <div style={{ direction: "rtl" }}>
                    <div className="line"></div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#CBA709",
                        fontWeight: "500",
                        fontSize: "20px",
                        marginTop: "15px",
                      }}
                    >
                      بيانات الخطيب/ الخطيبة{" "}
                    </p>
                    <div className="line"></div>
                    <p style={{ marginTop: "15px" }}>الرقم القومي: {id}</p>
                    <p>الاسم: {name}</p>
                    <p>رقم المحمول: {mobile}</p>
                    <p>البريد الإلكتروني: {email}</p>
                    <p>تاريخ الميلاد: {bdate}</p>
                    <p>العنوان: {address}</p>
                    <p>العنوان الحالي: {addressnow}</p>
                    <p>الكنيسة: {church}</p>
                    <p>أب الإعتراف: {father}</p>
                    <p>كنيسة أب الإعتراف: {fatherchurch}</p>
                    <p>كيف عرفت بالدورة ال ٥٩ للمخطوبين: {knowus}</p>
                  </div>
                  <hr />
                  <span style={{ color: "#333" }}>
                    <p>
                      {" "}
                      أقر بأن كافة البيانات المسجلة من خلالي صحيحة
                      <input
                        style={{ margin: "0 5px" }}
                        type="checkbox"
                        checked={accept}
                        onChange={handleCheckboxChange}
                      />
                    </p>
                    <p>وفي حالة تسجيلها خاطئة لا يتم قبول الإشتراك بالدورة</p>
                    كما أنه ليس لي الحق في المطالبة بشهادة اتمام حضور الدورة إلا
                    في حالة حضوري لجميع المحاضرات
                  </span>

                  <div className="line"></div>
                  <div className="edit-buttons-container">
                    <button
                      className="edit-button second-edit"
                      onClick={() => setCurrentPage(1)}
                      style={{
                        width: "70%",
                        display: "block",
                        margin: "15px auto 0 auto",
                      }}
                    >
                      تعديل البيانات
                    </button>
                  </div>

                  <div className="form-group">
                    <button className="btn-register" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>تم</span>
                    </button>
                    <br />
                    {/* <span style={{ textAlign: "center", color: "red" }}>
                      <li dir="rtl">
                        {" "}
                        اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل. (انظر طرق
                        الدفع)
                      </li>
                    </span> */}
                  </div>
                  {error && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  )}

                  {sucess && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-success"
                        }
                        role="alert"
                      >
                        {sucess}
                      </div>
                    </div>
                  )}
                </div>
              )}
              {/* Couple form */}
              <div hidden={single !== "couple"}>
                <div hidden={currentPage === 3}>
                  <span>:بيانات ال</span>
                  <select
                    onChange={onChangeDropdown}
                    value={selectedGender}
                    disabled={currentPage === 2}
                  >
                    <option disabled>اختر الطرف</option>
                    <option value={genders[0]}>{genders[0]}</option>
                    <option value={genders[1]}>{genders[1]}</option>
                  </select>
                </div>
              </div>

              {/*  the first and second page  */}
              {currentPage === 1 && single === "couple" && (
                <>
                  <div>
                    <hr />
                    <div className="page-indicator-container">
                      <div className="line"></div>
                      <div
                        className="page-indicator"
                        style={{
                          direction: "rtl",
                          color: "#CBA709",
                          fontWeight: "500",
                          fontSize: "20px",
                        }}
                      >
                        <span className="page-number">صفحة {currentPage}</span>
                        <span className="of-text">من</span>
                        <span className="total-pages">2</span>
                      </div>
                      <div className="line"></div>
                    </div>
                    <hr />
                    <div className="form-group">
                      <label htmlFor="id">
                        برجاء ادخال الرقم القومي كاملاً (ال ١٤ رقم)
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="id"
                        value={id}
                        onChange={onChangeId}
                        validations={[required, vid]}
                        placeholder="برجاء كتابة الأرقام (فقط) باللغة الانجليزية"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">
                        الاسم كاملًا باللغة العربية ( كما هو ببطاقة الرقم
                        القومى)
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="name"
                        value={name}
                        onChange={onChangeName}
                        validations={[required, vname]}
                      />
                    </div>
                    <div>
                      <label style={{ margin: "5px" }}>:النوع</label>
                      <select
                        value={selectedGender === "الخطيب" ? "male" : "female"}
                        validations={[required, vgender]}
                      >
                        <option
                          value={selectedGender === "الخطيب" ? "male" : "female"}
                        >
                          {selectedGender === "الخطيبة" ? "أنثى" : "ذكر"}
                        </option>
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor="mobile">
                        (whatsapp) رقم المحمول به واتساب
                      </label>
                      <PhoneInput
                        placeholder="Enter phone number"
                        value={mobile}
                        name="mobile"
                        defaultCountry="EG"
                        onChange={onChangeMobile}
                        rules={{
                          validate: (value) => vmobile(value),
                        }}
                        error={
                          mobile
                            ? vmobile
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                      {mobile && formatPhoneNumberIntl(mobile)}
                    </div>

                    <div className="form-group">
                      <label htmlFor="mobile">تاريخ الميلاد</label>
                      <Input
                        type="date"
                        className="form-control"
                        name="bdate"
                        value={bdate}
                        onChange={onChangeBdate}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="email">البريد الالكتروني</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="email"
                        value={email}
                        onChange={onChangeEmail}
                        validations={[required, validEmail]}
                        placeholder="example@gmail.com"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="address">
                        العنوان بالرقم القومي بالتفصيل
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="address"
                        value={address}
                        onChange={onChangeAddress}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="addressnow">
                        العنوان الحالي بالتفصيل
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="addressnow"
                        value={addressnow}
                        onChange={onChangeAddressnow}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="church">
                        (الكنيسة التابع لها (اسم الكنيسة والمنطقة/الحي
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="church"
                        value={church}
                        onChange={onChangeChurch}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="father">أب الإعتراف</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="father"
                        value={father}
                        onChange={onChangeFather}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="fatherchurch">كنيسة أب الإعتراف</label>
                      <Input
                        type="text"
                        className="form-control"
                        name="fatherchurch"
                        value={fatherchurch}
                        onChange={onChangeFatherchurch}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="knowus">
                        كيف عرفت بالدورة ال ٥٩ للمخطوبين؟
                      </label>
                      <Input
                        type="text"
                        className="form-control"
                        name="knowus"
                        value={knowus}
                        onChange={onChangeKnowus}
                        validations={[required]}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="tfatherchurch">
                        (برجاء تحميل صورة واضحة لبطاقة الرقم القومى (وجه
                      </label>
                      <span
                        className="file-upload"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        {fileone && fileone.name}
                        {!fileone && (
                          <span
                            className="file-placeholder"
                            style={{ margin: "5px 0", textAlign: "center" }}
                          >
                            Choose a file
                          </span>
                        )}
                        <input
                          type="file"
                          onChange={onFileChangeone}
                          className="file-input"
                          id="fileInput"
                          style={{ display: "none" }}
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="form-group">
                      <label htmlFor="tfatherchurch">
                        (تحميل صورة واضحة لبطاقة الرقم القومى (ظهر
                      </label>
                      <span
                        className="file-upload"
                        onClick={() =>
                          document.getElementById("fileInput2").click()
                        }
                      >
                        {filetwo && filetwo.name}
                        {!filetwo && (
                          <span
                            className="file-placeholder"
                            style={{ margin: "5px 0", textAlign: "center" }}
                          >
                            Choose a file
                          </span>
                        )}
                        <input
                          type="file"
                          onChange={onFileChangetwo}
                          className="file-input"
                          id="fileInput2"
                          style={{ display: "none" }}
                        />
                      </span>
                    </div>
                    <hr />
                    <div className="form-group">
                      <label htmlFor="tfatherchurch">
                        برجاء تحميل صورة شخصية
                      </label>
                      <span
                        className="file-upload"
                        onClick={() =>
                          document.getElementById("fileInput3").click()
                        }
                      >
                        {filethree && filethree.name}
                        {!filethree && (
                          <span
                            className="file-placeholder"
                            style={{ margin: "5px 0", textAlign: "center" }}
                          >
                            Choose a file
                          </span>
                        )}
                        <input
                          type="file"
                          onChange={onFileChangethree}
                          className="file-input"
                          id="fileInput3"
                          style={{ display: "none" }}
                        />
                      </span>
                    </div>
                    <div>
                      <hr />
                    </div>
                    {showNextAndPreviousButtons && (
                      <div>
                        <button
                          className="next-button"
                          onClick={handleNextClick}
                          style={{ width: "100%" }}
                        >
                          <FontAwesomeIcon icon={faRightLong} rotation={180} />{" "}
                          إذهب لاستكمال بيانات الطرف الآخر
                        </button>
                        {/* {error && <p className="error-message">{error}</p>} */}
                        {message1 && (
                          <p className="error-message">{message1}</p>
                        )}
                      </div>
                    )}
                  </div>
                </>
              )}

              {currentPage === 2 && (
                <div>
                  <hr />

                  <div className="page-indicator-container">
                    <div className="line"></div>
                    <div
                      className="page-indicator"
                      style={{
                        direction: "rtl",
                        color: "#CBA709",
                        fontWeight: "500",
                        fontSize: "20px",
                      }}
                    >
                      <span className="page-number">صفحة {currentPage}</span>
                      <span className="of-text">من</span>
                      <span className="total-pages">2</span>
                    </div>
                    <div className="line"></div>
                  </div>

                  <hr />
                  <div className="form-group">
                    <label htmlFor="tid">
                      برجاء ادخال الرقم القومي كاملاً (ال14 رقم)
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="tid"
                      value={tid}
                      onChange={onChangetId}
                      validations={[required, vid]}
                      placeholder="برجاء كتابة الأرقام (فقط) باللغة الانجليزية"
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tname">
                      الاسم كاملاً باللغة العربية كما هو ببطاقة الرقم القومى
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="tname"
                      value={tname}
                      onChange={onChangetName}
                      validations={[required, vname]}
                    />
                  </div>

                  <div>
                    <label style={{ margin: "5px" }}>:النوع</label>
                    <select
                      value={selectedGender === "الخطيب" ? "male" : "female"}
                      validations={[required, vgender]}
                    >
                      <option
                        value={selectedGender === "الخطيب" ? "male" : "female"}
                      >
                        {selectedGender === "الخطيبة" ? "أنثى" : "ذكر"}
                      </option>
                    </select>
                  </div>

                  <div className="form-group">
                    <label htmlFor="mobile">
                      (whatsapp) رقم المحمول به واتساب
                    </label>
                    <PhoneInput
                      placeholder="Enter phone number"
                      value={tmobile}
                      name="tmobile"
                      defaultCountry="EG"
                      onChange={onChangetMobile}
                      rules={{
                        validate: (value) => vmobile(value),
                      }}
                      error={
                        tmobile
                          ? vmobile
                            ? undefined
                            : "Invalid phone number"
                          : "Phone number required"
                      }
                    />
                    {tmobile && formatPhoneNumberIntl(tmobile)}
                  </div>

                  <div className="form-group">
                    <label htmlFor="tbdate">تاريخ الميلاد</label>
                    <Input
                      type="date"
                      className="form-control"
                      name="tbdate"
                      value={tbdate}
                      onChange={onChangetBdate}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="temail">البريد الإلكتروني</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="temail"
                      value={temail}
                      onChange={onChangetEmail}
                      validations={[required, validEmail]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="taddress">
                      العنوان بالرقم القومي بالتفصيل
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="taddress"
                      value={taddress}
                      onChange={onChangetAddress}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="taddressnow">العنوان الحالي بالتفصيل</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="taddressnow"
                      value={taddressnow}
                      onChange={onChangetAddressnow}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tchurch">
                      (الكنيسة التابع لها (اسم الكنيسة والمنطقة/الحي
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="tchurch"
                      value={tchurch}
                      onChange={onChangetChurch}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tfather">أب الإعتراف</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="tfather"
                      value={tfather}
                      onChange={onChangetFather}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tfatherchurch">كنيسة أب الإعتراف</label>
                    <Input
                      type="text"
                      className="form-control"
                      name="tfatherchurch"
                      value={tfatherchurch}
                      onChange={onChangetFatherchurch}
                      validations={[required]}
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="tknowus">
                      كيف عرفت بالدورة ال ٥٩ للمخطوبين؟
                    </label>
                    <Input
                      type="text"
                      className="form-control"
                      name="tknowus"
                      value={tknowus}
                      onChange={onChangetKnowus}
                      validations={[required]}
                    />
                  </div>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="tfatherchurch">
                      (برجاء تحميل صورة واضحة لبطاقة الرقم القومى (وجه
                    </label>
                    <span
                      className="file-upload"
                      onClick={() =>
                        document.getElementById("fileInput").click()
                      }
                    >
                      {tfileone && tfileone.name}
                      {!tfileone && (
                        <span
                          className="file-placeholder"
                          style={{ margin: "5px 0", textAlign: "center" }}
                        >
                          Choose a file
                        </span>
                      )}
                      <input
                        type="file"
                        onChange={ontFileChangeone}
                        className="file-input"
                        id="fileInput"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="tfatherchurch">
                      (تحميل صورة واضحة لبطاقة الرقم القومى (ظهر
                    </label>
                    <span
                      className="file-upload"
                      onClick={() =>
                        document.getElementById("fileInput2").click()
                      }
                    >
                      {tfiletwo && tfiletwo.name}
                      {!tfiletwo && (
                        <span
                          className="file-placeholder"
                          style={{ margin: "5px 0", textAlign: "center" }}
                        >
                          Choose a file
                        </span>
                      )}
                      <input
                        type="file"
                        onChange={ontFileChangetwo}
                        className="file-input"
                        id="fileInput2"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                  <hr />
                  <div className="form-group">
                    <label htmlFor="tfatherchurch">
                      برجاء تحميل صورة شخصية{" "}
                    </label>
                    <span
                      className="file-upload"
                      onClick={() =>
                        document.getElementById("fileInput3").click()
                      }
                    >
                      {tfilethree && tfilethree.name}
                      {!tfilethree && (
                        <span
                          className="file-placeholder"
                          style={{ margin: "5px 0", textAlign: "center" }}
                        >
                          Choose a file
                        </span>
                      )}
                      <input
                        type="file"
                        onChange={ontFileChangethree}
                        className="file-input"
                        id="fileInput3"
                        style={{ display: "none" }}
                      />
                    </span>
                  </div>
                  <hr />

                  <button
                    style={{
                      width: "80%",
                      display: "block",
                      margin: "10px auto",
                    }}
                    className="previous-button"
                    onClick={onPreviousPage}
                  >
                    الرجوع لبيانات الطرف الأول{" "}
                    <FontAwesomeIcon icon={faLeftLong} rotation={180} />
                  </button>
                </div>
              )}

              {currentPage === 2 && single === "couple" && (
                <div>
                  <div className="form-group">
                    <button
                      className="btn-register"
                      onClick={handleNextToThirdPage}
                      disabled={loading}
                    >
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>تسجيل</span>
                    </button>
                    {/* <span style={{ textAlign: "center", color: "red" }}>
                      <li dir="rtl">
                        {" "}
                        اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل. (انظر طرق
                        الدفع)
                      </li>
                    </span> */}
                  </div>
                  {/* {error && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  )} */}
                  {message2 && <p className="error-message">{message2}</p>}
                  {sucess && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-success"
                        }
                        role="alert"
                      >
                        {sucess}
                      </div>
                    </div>
                  )}
                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </div>
              )}
              {currentPage === 3 && (
                <div>
                  <div style={{ direction: "rtl" }}>
                    <div className="line"></div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#CBA709",
                        fontWeight: "500",
                        fontSize: "20px",
                        marginTop: "15px",
                      }}
                    >
                      بيانات{" "}
                      {selectedGender === genders[0] ? genders[1] : genders[0]}
                    </p>
                    <div className="line"></div>
                    <p style={{ marginTop: "15px" }}>الرقم القومي: {id}</p>
                    <p>الاسم: {name}</p>
                    <p>رقم المحمول: {mobile}</p>
                    <p>البريد الإلكتروني: {email}</p>
                    <p>تاريخ الميلاد: {bdate}</p>
                    <p>العنوان: {address}</p>
                    <p>العنوان الحالي: {addressnow}</p>
                    <p>الكنيسة: {church}</p>
                    <p>أب الإعتراف: {father}</p>
                    <p>كنيسة أب الإعتراف: {fatherchurch}</p>
                    <p>كيف عرفت بالدورة ال ٥٩ للمخطوبين: {knowus}</p>
                    <div className="line"></div>
                    <p
                      style={{
                        textAlign: "center",
                        color: "#CBA709",
                        fontWeight: "500",
                        fontSize: "20px",
                        marginTop: "15px",
                      }}
                    >
                      بيانات {selectedGender}
                    </p>
                    <div className="line"></div>
                    <p style={{ marginTop: "15px" }}>الرقم القومي: {tid}</p>
                    <p>الاسم: {tname}</p>
                    <p>رقم المحمول: {tmobile}</p>
                    <p>تاريخ الميلاد: {tbdate}</p>
                    <p>البريد الإلكتروني: {temail}</p>
                    <p>العنوان: {taddress}</p>
                    <p>العنوان الحالي: {taddressnow}</p>
                    <p>لكنيسة: {tchurch}</p>
                    <p>أب الإعتراف: {tfather}</p>
                    <p>كنيسة أب الإعتراف: {tfatherchurch}</p>
                    <p>كيف عرفت بالدورة ال ٥٩ للمخطوبين: {tknowus}</p>
                  </div>
                  <hr />
                  <span style={{ color: "#333" }}>
                    <p>
                      {" "}
                      أقر بأن كافة البيانات المسجلة من خلالي صحيحة
                      <input
                        style={{ margin: "0 5px" }}
                        type="checkbox"
                        checked={accept}
                        onChange={handleCheckboxChange}
                      />
                    </p>
                    <p>وفي حالة تسجيلها خاطئة لا يتم قبول الإشتراك بالدورة</p>
                    كما أنه ليس لي الحق في المطالبة بشهادة اتمام حضور الدورة إلا
                    في حالة حضوري لجميع المحاضرات
                  </span>
                  <div className="line"></div>
                  <div className="edit-buttons-container">
                    <button
                      className="edit-button first-edit"
                      onClick={() => setCurrentPage(2)}
                    >
                      تعديل بيانات الطرف الثاني
                    </button>
                    <button
                      className="edit-button second-edit"
                      onClick={onPreviousPage}
                    >
                      تعديل بيانات الطرف الأول
                    </button>
                  </div>

                  <div className="form-group">
                    <button className="btn-register" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>تم</span>
                    </button>
                    <br />
                    {/* <span style={{ textAlign: "center", color: "red" }}>
                      <li dir="rtl">
                        {" "}
                        اخر موعد للدفع بعد ٣ أيام من تاريخ التسجيل. (انظر طرق
                        الدفع)
                      </li>
                    </span> */}
                  </div>
                  {error && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-danger"
                        }
                        role="alert"
                      >
                        {error}
                      </div>
                    </div>
                  )}

                  {sucess && (
                    <div className="form-group">
                      <div
                        className={
                          successful
                            ? "alert alert-success"
                            : "alert alert-success"
                        }
                        role="alert"
                      >
                        {sucess}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </Form>
      </div>
    </div>
  );
};
export default RegistrationForm;
